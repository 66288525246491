import { Link } from 'gatsby'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import React from 'react'

const Footer = props => {
  const intl = useIntl();

  return <footer id="footer">
    {/* <section>
      <h2>Aliquam sed mauris</h2>
      <p>
        Sed lorem ipsum dolor sit amet et nullam consequat feugiat consequat
        magna adipiscing tempus etiam dolore veroeros. eget dapibus mauris. Cras
        aliquet, nisl ut viverra sollicitudin, ligula erat egestas velit, vitae
        tincidunt odio.
      </p>
      <ul className="actions">
        <li>
          <Link to="/generic" className="button">
            Learn More
          </Link>
        </li>
      </ul>
    </section> */}
    <section>
      <h2><FormattedMessage id="related" /></h2>
      <dl className="alt">
        <dt><FormattedMessage id="email" /></dt>
        <dd>pxmagestudio@gmail.com</dd>
        <dt><FormattedMessage id="tutorial" /></dt>
        <dd><a target="_blank" href="https://www.yuque.com/pocv40/in74g9/vgkp8g"><FormattedMessage id="yuque" /></a></dd>
        <dt><FormattedMessage id="feedbacks" /></dt>
        <dd><a target="_blank" href="https://www.yuque.com/pocv40/in74g9/ll75wa"><FormattedMessage id="yuque" /></a></dd>
        {intl.locale === 'zh' && (
            <a
              style={{ color: '#a5a5a5', marginTop: '1rem', display: 'block', fontSize: '14px', borderBottom: 'none'}}
              href="https://beian.miit.gov.cn/"
              target="_blank"
            >
             浙ICP备16041826号-5
            </a>
          )}
      </dl>
      {/* <ul className="icons">
        <li>
          <a
            href="https://twitter.com/huntaroSan"
            className="icon fa-twitter alt"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a href="https://codebushi.com" className="icon fa-facebook alt">
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a href="https://codebushi.com" className="icon fa-instagram alt">
            <span className="label">Instagram</span>
          </a>
        </li>
        <li>
          <a
            href="https://github.com/codebushi/gatsby-starter-stellar"
            className="icon fa-github alt"
          >
            <span className="label">GitHub</span>
          </a>
        </li>
        <li>
          <a href="https://codebushi.com" className="icon fa-dribbble alt">
            <span className="label">Dribbble</span>
          </a>
        </li>
      </ul> */}
    </section>
  </footer>
}

export default Footer
